html.philosphy {

    .tab-menu {
        nav {
            div {
                background-color: #bf955a;
                padding: .5rem 0;

                @media (max-width: 575.98px) {
                    flex-direction: column;
                }

                a {
                    color: #ffffff;
                    padding: 0rem 2rem;
                    font-family: "Gotham Pro Medium";
                    font-size: 15px;
                    text-transform: uppercase;
                    letter-spacing: .5px;

                    @media (max-width: 575.98px) {
                        text-align: center;
                        margin: 5px 0;
                    }

                    &:last-child {
                        border-left: 1px solid #ffffff;

                        @media (max-width: 575.98px) {
                            border-left: 0;
                        }
                    }
                }
            }
        }
    }

    h2 {
        color: #bf955a;
        text-transform: uppercase;
        border-bottom: 2px solid #bf955a;
        padding-bottom: 7px;
        margin-bottom: 20px;
    }

    ul {
        margin: 0;
    }

    ul.dashed {
        list-style-type: none;
    }

    ul.dashed > li {
        text-indent: -10px;
        margin-bottom: 10px;
    }

    ul.dashed > li:before {
        content: "-";
        text-indent: -5px;
    }

    .our-ingredients {
        background-image: url("../img/home/leaf-dark.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    .category-container:not(:last-child) {
        .spa-container:last-child {
            border-bottom: 2px solid #bf955a;
            margin-bottom: 25px;
            padding-bottom: 25px;
        }
    }

    .blog-items {
        > div {
            padding: 0px;
        }

        p {
            font-size: 13px;
            font-family: "Gotham Pro Light";
            color: #414042;
            line-height: 1.2;
            text-align: left;
        }
    }

    .spa-container {
        margin-bottom: 25px;

        .content-box {
            .text-content {

                > div {
                    @media (min-width: 992px) {
                        width: 85%;
                    }
                }

            }

            img {
                object-fit: cover;
                min-height: 100%;
            }
        }
    }

}

html.about {

    .about-banner {
        background-image: url("../img/About-Image.png");
        min-height: 440px;

        .banner-content {
            min-height: 300px;
            display: flex;
            align-items: center;

            h3 {
                padding-bottom: 5px;
                border-bottom: 2px solid;
                width: 75%;
                @media (max-width: 991.98px) {
                    color: #fcd295 !important;
                }
            }

            p {
                font-size: .8rem;
                color: #ffffff;
                font-family: Gotham Pro Light;
            }
        }
    }

    strong {
        color: #bf955a;
        font-family: "Gotham Pro Bold";
        font-size: 15px;
        display: block;
        margin-bottom: 5px;
    }

}

html.blog {

    .blog-banner {
        background-image: url("../img/blog-banner-bg.png");
        min-height: 440px;
    }

    .blog-menu-container {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        .nav-item {

            @media (max-width: 575.98px) {
                width: 100%;
                a {
                    border-bottom: 2px solid #bf955a;
                }
            }
            @media (min-width: 576px) and (max-width: 991.98px) {
                width: 50%;
                a {
                    border-bottom: 2px solid #bf955a;
                }
            }
            @media (min-width: 992px) {
                width: 20%;
                &:nth-child(1) a {
                    border-bottom: 2px solid #bf955a;
                }
                &:nth-child(2) a {
                    border-bottom: 2px solid #bf955a;
                }
                &:nth-child(3) a {
                    border-bottom: 2px solid #bf955a;
                }
                &:nth-child(4) a {
                    border-bottom: 2px solid #bf955a;
                }
                &:nth-child(5) a {
                    border-bottom: 2px solid #bf955a;
                }
            }

            a {
                color: #bf955a;
                text-decoration: none;
                font-family: Gotham Pro Bold;
                font-size: .85rem;
                width: auto;
                padding-bottom: 7px;
                min-width: 168px;
                display: inline-block;
                padding-left: 0px;
                padding: 15px 0px;
                cursor: pointer;

            }

        }

    }

    .blog-items {
        min-height: 300px;

        div{
            a, a:hover {
                text-decoration: none;
            }

            h5 {
                margin-bottom: 5px;
            }

            p {
                font-size: 13px;
                line-height: 1rem;
                text-overflow: ellipsis;
                word-wrap: break-word;
                overflow: hidden;
                max-height: 3rem;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                color: #4A4A4A;
            }
        }
    }

}

html.news {
    .blog-items {
        a {
            text-decoration: none;

            div {
                h5 {
                    margin-bottom: 5px;
                }

                span {
                    font-size: 12px;
                    line-height: 1rem;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                    overflow: hidden;
                    max-height: 3rem;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                }
            }
        }
    }
}

html.contact {

    .contact-banner {
        background-image: url("../img/contact-banner.jpg");
        min-height: 440px;

        .banner-content {
            min-height: 300px;
            display: flex;
            align-items: center;

            h3 {
                padding-bottom: 5px;
                border-bottom: 2px solid;
                width: 75%;
                @media (max-width: 991.98px) {
                    color: #fcd295 !important;
                }
            }

            p {
                font-size: .8rem;
                color: #ffffff;
                font-family: Gotham Pro Light;
            }
        }
    }

    .contact-form-container {
        form {
            .form-group {
                margin-bottom: 1.2rem;

                label:first-child:not(.error) {
                    font-size: 15px;
                    color: #bf955a;
                    font-family: Gotham Pro Bold !important;
                    text-transform: uppercase;
                }

                .error {
                    color: red;
                    font-size: 12px;
                    margin-top: 5px;
                }
            }
        }
    }

    .contact-container {
        //background-color: #f6f2ee;

        #map {
            height: 100%;
        }

        .map-responsive {
            overflow: hidden;
            padding-bottom: 76.25%;
            position: relative;
            height: 0;
        }

        .map-responsive iframe {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
        }

        .contact-details {
            background-image: url("../img/home/leaf.png");
        }

    }
}

html.partners {
    .title-container {
        background-color: #f6f2ee;
        background-image: url("../img/home/leaf.png");
        background-position: center;
        background-repeat: no-repeat;
        min-height: 440px;
    }
}

html.voucher {
    .voucher-content-container {
        form {
            label {
                font-size: .8rem;
            }
        }
    }
}

html.view-cart {

    h2 {
        border-bottom: 1px solid #bf955a;
    }

    .view-cart-table-container {

        .table {
            margin-bottom: 0px;

            thead {
                th {
                    color: #bf955a;
                    font-size: 13px;
                    border-bottom: 1px solid rgba(191, 149, 90, 0.20);
                    border-top: 1px solid rgba(191, 149, 90, 0.20);
                }
            }

            tr {

                &:first-child {
                    td {
                        border-top: 0px;
                    }
                }

                td {
                    border-top: 1px solid #c69c6d;
                    vertical-align: middle;

                    @media (max-width: 575.98px) {
                        padding: 0.75rem 5px;
                    }

                    img {
                        max-width: 75px;
                        max-height: 100px;

                        @media (max-width: 575.98px) {
                            max-width: 50px;
                            max-height: 75px;
                        }

                    }

                    strong, span {
                        color: #bf955a;
                        margin-bottom: 0px;
                        line-height: 21px;
                        font-weight: bold;
                        font-family: "Gotham Pro Medium";
                        font-size: 13px;
                    }

                    strong {
                        text-transform: uppercase;
                        display: block;
                        margin-bottom: 15px;
                        margin-top: 12px;

                        @media (max-width: 575.98px) {
                            font-size: 11px !important;
                            margin-bottom: 10px;
                        }
                    }

                    div {
                        margin-bottom: 10px;

                        @media (max-width: 991.98px) {
                            flex-direction: column;

                            .btn {
                                margin-left: 0px !important;
                            }
                        }

                        span {
                            color: #414042;
                            font-weight: normal;
                            font-family: "Gotham Pro Light";
                            margin-left: 15px;
                            margin-right: 25px;
                        }

                        div {
                            a {
                                .btn {
                                    padding: 0.35rem .5rem !important;
                                }
                            }
                        }

                    }

                    .form-control {
                        border: 2px solid #bf955a;
                        border-radius: 0px;
                        max-width: 100px;
                        min-width: 70px;
                        padding: 8px 10px 4px 10px;

                        @media (max-width: 575.98px) {
                            max-width: 75px;
                            min-width: 70px;
                            padding: 5px 10px 1px 10px;
                        }
                    }

                    input[type="number"] {
                        //-webkit-appearance: textfield;
                        //-moz-appearance: textfield;
                        //appearance: textfield;

                        background-image: url("../img/input_up_down_arrow.svg");
                        background-position: center right;
                        background-repeat: no-repeat;
                        -webkit-appearance: textfield;
                        background-origin: content-box;
                    }

                    input[type=number]::-webkit-inner-spin-button,
                    input[type=number]::-webkit-outer-spin-button {
                        //-webkit-appearance: none;
                        opacity: 0;
                    }

                    //input[type=number]::after{
                    //  right: 0;
                    //  top: 0;
                    //  width: 100%;
                    //  height: 100%;
                    //  position: absolute;
                    //  content: 'a';
                    //  background-image: url(../img/input_up_down_arrow.svg);
                    //  bottom: 0;
                    //  left: 0px;
                    //}

                }
            }
        }
    }

    .total-column {
        color: #c49e68;
        font-size: .85rem;
        font-family: "Gotham Pro Bold";
        letter-spacing: .5px;
        text-transform: uppercase;
    }
}

html.login {
    h4 {
        margin-bottom: 2rem;
    }

    .forms-container {

        form {
            max-width: 330px;

            .form-control {
                border: 1px solid #D7D7D7;
                border-radius: 0px;
                padding: 8px 10px 4px 10px;
            }

            .form-group {
                label {
                    color: #BF955A;
                    letter-spacing: 0.47px;
                    line-height: 26px;
                }
            }

        }
    }

    .register-container {
        @media (min-width: 768px) {
            border-left: 1px solid rgba(191, 149, 90, 0.41);
        }

        p {
            color: #3A3A3A;
            font-family: "Gotham Pro Light";
            letter-spacing: 0.47px;
            line-height: 25px;
            //font-size: 13px;
        }

    }
}

html.billing {
    h4 {
        margin-bottom: 2rem;
    }

    .forms-container {

        form {

            .form-group {
                max-width: 330px;

                .form-control {
                    border: 1px solid #D7D7D7;
                    border-radius: 0px;
                    padding: 8px 10px 4px 10px;
                }

                label {
                    color: #BF955A;
                    letter-spacing: 0.47px;
                    line-height: 26px;
                }

            }

            //.form-group-lg{
            //  max-width: 360px;
            //}

            .another-container {
                .row {
                    @media (min-width: 768px) {
                        border-left: 1px solid rgba(191, 149, 90, 0.41);
                    }
                }
            }

            .tables-container {
                color: #616161;

                .form-check-label {
                    color: #414042;
                    letter-spacing: 0.47px;
                    line-height: 26px;
                }

                strong {
                    color: #BF955A;
                    font-weight: bold;
                    font-family: "Gotham Pro Bold";
                    letter-spacing: .75px;
                }

                .table-product-details {
                    tr {
                        td {
                            &:first-child {
                                color: #BF955A;
                            }
                        }
                    }
                }

                h6 {
                    font-family: "Gotham Pro Medium";
                    //text-transform: none;
                    color: #BF955A;
                    letter-spacing: 0.53px;
                    line-height: 26px;
                }
            }

        }
    }
}

html.purchase-history {

    .purchase-history-container {

        #accordion {
            .card {
                border-radius: 0px;
                border: 0px;
                border-bottom: 1px solid #BF955A;

                &:first-child {
                    .card-header {
                        border-top: 1px solid #BF955A;
                    }
                }

                &:last-child {
                    //border-bottom: 0px !important;
                }

                .card-header {
                    position: relative;
                    border-bottom: 1px solid #BF955A;
                    border-top: 0px solid #BF955A;
                    border-radius: 0px;
                    display: flex;
                    padding-right: 50px;
                    padding-left: 0px;
                    cursor: pointer;

                    &:after {
                        content: "\f0d8";
                        font: normal normal normal 14px/1 FontAwesome;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 25px;
                        color: #BF955A;
                        font-size: 17px;

                    }

                    div {
                        margin-right: 25px;
                        color: #BF955A;
                        font-size: 13px;

                        strong {
                            font-family: "Gotham Pro Medium";
                        }

                        span {
                            font-family: "Gotham Pro";
                        }
                    }
                }

                .collapsed {
                    border-bottom: 0px solid #BF955A !important;

                    &:after {
                        content: "\f0d7" !important;
                        font: normal normal normal 14px/1 FontAwesome;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 25px;
                        color: #BF955A;
                        font-size: 17px;

                    }
                }

                .card-body {
                    padding: 1.25rem 0rem;
                }
            }
        }

        .purchase-history-table-container {

            .table {
                margin-bottom: 0px;

                thead {
                    th {
                        color: #bf955a;
                        font-size: 13px;
                        border-bottom: 1px solid rgba(191, 149, 90, 0.20);
                        border-top: 0px;
                        white-space: nowrap;
                        word-break: normal;
                        padding: .75rem 5px;
                    }
                }

                tr {

                    td {
                        //border-top: 1px solid rgba(191,149,90,0.20);
                        vertical-align: middle;
                        text-align: left;

                        img {
                            max-width: 75px;
                            max-height: 100px;
                        }

                        p, span {
                            color: #bf955a;
                            margin-bottom: 0px;
                            line-height: 21px;
                            font-size: 13px;
                            font-family: "Gotham Pro Medium";

                        }

                        &:last-child {
                            min-width: 100px;
                        }
                    }
                }

                tr.border-none {
                    td {
                        border-top: 0px;
                        padding: .5rem .75rem;
                        @media (max-width: 767.98px) {
                            padding: .5rem 5px;
                        }
                    }
                }

                td {
                    border-top: 1px solid rgba(191, 149, 90, 0.20);
                    @media (max-width: 767.98px) {
                        padding: .75rem 5px;
                        font-size: 12px !important;


                        img {
                            max-width: 50px !important;
                            max-height: 75px !important;
                        }

                        p {
                            font-size: 12px !important;
                        }
                        span {
                            font-size: 12px !important;
                        }
                    }
                }

            }
        }
    }
}

html.order-processed {
    p {
        color: #414042;
        font-family: GothamPro-Light;
        font-size: 13px;
        letter-spacing: 0.47px;
        text-align: center;
        line-height: 26px;
    }

    .payment-icons-container{
        div{
            padding: 0 6px;
            img{
                max-height: 30px;
                height: 30px;
            }
        }
    }

    .terms-condition-modal{
        .modal-body{
            .contents{
                line-height: 1.5;
                color: #4f4f4f;

                a{
                    text-decoration: none;
                }
            }

            .btn{
                border-radius: 0 !important;
            }
        }
    }
}

html.gallery {
    .thumb-preview {
        div {
            padding: 7px;

            .prev {
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: 5rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: #fff;
                left: 15px;
                cursor: pointer;
            }

            .next {
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: 5rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: #fff;
                right: 15px;
                cursor: pointer;
            }

            img {
                width: 100%;
            }
        }
    }

    .thumb-container {
        div {
            padding: 7px;

            img {
                max-height: 150px;
                object-fit: cover;
                min-width: 100%;
                height: 150px;
            }
        }
    }
}

html.careers{
    .career-banner {
        background-image: url("../img/career-banner.png");
        min-height: 440px;

        .banner-content {
            min-height: 300px;
            display: flex;
            align-items: center;

            h3 {
                padding-bottom: 5px;
                border-bottom: 2px solid;
                width: 75%;
                @media (max-width: 991.98px) {
                    color: #fcd295 !important;
                }
            }

            p {
                font-size: .8rem;
                color: #ffffff;
                font-family: Gotham Pro Light;
            }
        }
    }
}
