html.spa {

  .cuisine-banner {
    background-image: url("../img/spa-banner-bg.png");
      min-height: 440px;

    .banner-content {
      padding-top: 7rem;
      padding-bottom: 7rem;
      flex-direction: column;
      align-items: start;

      @media (max-width: 575.98px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
      }

    }

  }

  .spa-tab-menu-container {
    nav{
        @media (max-width: 575.98px) {
            margin: 0 -15px;
        }

      div{
        background-color: #bf955a;
        padding: .5rem 0;

          @media (max-width: 575.98px) {
              flex-direction: column;
          }

        a{
          color: #ffffff;
          padding: 0rem 2rem;
          font-family: "Gotham Pro Medium";
          font-size: 15px;
          text-transform: uppercase;
          letter-spacing: .5px;

            @media (max-width: 575.98px) {
                text-align: center;
                margin: 5px 0;
            }

          &:last-child{
            border-left: 1px solid #ffffff;

              @media (max-width: 575.98px) {
                  border-left: 0;
              }

          }
        }
      }
    }

    .menu-section{
      .slider-container{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
      .menu-container{
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;

        .menu-item {
          text-align: center;

          &:focus{
            outline: none;
            border: 0px;
          }

          a{
            color: #bf955a;
            text-decoration: none;
            font-family: Gotham Pro Bold;
            font-size: .85rem;
            width: auto;
            padding-bottom: 7px;
            min-width: 168px;
            display: inline-block;
            padding-left: 0px;

            @media (max-width: 767.98px) {
              border-bottom: 2px solid;
            }

            &:hover{
              border-bottom: 2px solid;
            }
            &:focus{
              outline: none;
              //border: 0px;
            }

            &.active{
              border-bottom: 2px solid;
            }
          }

        }

      }
      .menu-items-container{
        #tabContents{
          table{
            font-family: "Gotham Pro";

            thead{
              h3{
                color: #bf955a;
                padding-bottom: 5px;
                display: inline-block;
                border-bottom: 2px solid;
                text-transform: uppercase;
              }
            }

            tr{
              margin-bottom: 10px;
              td{
                font-size: 13px;
                border: 0px;

                b{
                  font-family: "Gotham Pro Bold";
                  font-size: 16px;
                  color: #414042;
                  display: block;
                }

                strong{
                  font-family: "Gotham Pro Bold";
                  font-size: 13px;
                  color: #414042;
                  display: block;
                }
              }
              th{
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 18px;
                border: 0px;
              }
            }
          }
        }
      }
    }

  }

    .content{
        background: #f6f2ee;
        .text-container {
            padding: 55px 15px 70px 15px;
            background-image: url("../img/home/leaf.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            h3 {
                text-transform: uppercase;
                line-height: 1;
                text-align: center;
                display: inline-block;
                padding: 0px 4rem 15px 4rem;
                border-bottom: 2px solid #bf955a;
                margin-bottom: 20px;
            }

            p {
                font-size: 13px;
                color: #414042;
                font-family: "Gotham Pro Light";
            }
        }
    }

    .menu-download{
        .menu-item{
            @media (min-width: 992px) {
                width: 100% !important;
            }
        }
    }
}

html.spa-only{

    .content{
        background: none !important;
    }
    .weekday-menu {
        font-size: 15px;
        font-family: "Gotham Pro Bold";
        color: rgb(191, 149, 90);
        text-transform: uppercase;
        line-height: 1.2;
        text-align: left;

        @media (max-width: 575.98px) {
            padding-bottom: 30px;
        }

        .nav-item{
            border-bottom: 2px solid #bf955a;
            .nav-link{
                padding: 0.7rem 1rem 0.4rem 0rem;
            }
        }
    }

    .menu-details-container{
        padding-top: 11px;
        color: #414042;

        h3{
            text-transform: uppercase;
            line-height: 1;
            text-align: left;
            display: inline-block;
            padding: 0px 9rem 10px 0rem;
            border-bottom: 2px solid #bf955a;
            margin-bottom: 20px;
            min-width: 280px;
        }

        h5{
            margin-bottom: 0px;

        }
        .item{
            margin-bottom: 15px;

            strong{
                font-family: "Gotham Pro Bold";
                font-size: 13px;
            }
            span{
                display:block;
                font-family: "Gotham Pro Light";
                font-size: 13px;

                p{
                    //margin-bottom: 0;
                }
            }

            .form-check{
                .custom-control{
                    display: flex;
                    align-items: center;
                    padding-left: 17px;

                    input{
                        &:focus{
                            outline: none !important;
                            box-shadow: none !important;
                        }
                    }
                    label{
                        color: #bf955a;
                        font-size: 13px;
                        line-height: 1;
                        text-transform: capitalize;
                    }
                }

                .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
                    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23BF955A' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
                }

                .custom-control-label::before {
                    background-color: #fff;
                    border: 2px solid #bf9559;
                }

            }

            label{
                color: #bf955a;
                font-size: 13px;
                line-height: 1;
                text-transform: capitalize;

                p{
                    display: inline-block;
                    margin-bottom: 0;
                }
            }

        }

        .item-seperater{
            border-bottom: 2px solid #bf955a;
            min-width: 280px;
            max-width: 350px;
            padding-top: 10px;
        }
    }

    .menu-download{
        border-bottom: 0 !important;
    }
}
