html.product-item{

  .product-item-container{
    padding-bottom: 2rem;

    .product-details{

      font-size: .8rem;

      p{
        color: #414042;
        font-family: Gotham Pro;
      }
      span{
        display: block;
        color: #414042;
        font-family: "Gotham Pro Light";
        margin-bottom: .5rem;
      }

      form{
        label{
          margin-bottom: 0px;
          font-family: "Gotham Pro Bold";
          font-size: 1rem;
          letter-spacing: 1px;
          padding-top: 3px;
        }
        i{
          cursor: pointer;
          font-size: 2.5rem;
        }
        input{
          display: inline-block;
          width: auto;
          max-width: 80px;
            min-width: 65px;
          padding: 0.375rem 0.3rem;
          text-align: center;
        }
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
          }

          /* Firefox */
          input[type=number] {
              -moz-appearance: textfield;
          }

        button{
          background-color: #bf955a;
          border: 0px;
          border-radius: 0px;
          color: #fff;
          padding: 0.375rem 1.5rem;
          padding-top: .5rem;
          max-height: 35px;

          @media (max-width: 575.98px) {
            padding: 0.375rem 0.5rem;
          }
        }
      }
    }
  }

  .detail-container{
    font-size: .8rem;
    margin-bottom: 3rem;
    p{
      line-height: 1.1rem;
    }
  }

  .suggestion-container{
    margin-bottom: .5rem;

    h3{
      margin-bottom: 1.5rem;
    }
    a{
      text-decoration: none;
    }
  }

  .blog-items{
    a{
      text-decoration: none;
    }
  }

}
