html.product {

    .text-container {

        h3 {
            text-transform: uppercase;
            line-height: 1;
            text-align: center;
            display: inline-block;
            padding: 0px 4rem 15px 4rem;
            border-bottom: 2px solid #bf955a;
            margin-bottom: 20px;
        }

        p {
            font-size: 13px;
            color: #414042;
            font-family: "Gotham Pro Light";
        }
    }

    .menu-container {
        nav {
            .breadcrumb {
                background-color: transparent;
                padding-left: 0px;
                margin-bottom: 0px;
            }

            li, li a {
                color: #c49e68;
                text-decoration: none;
                font-size: 12.5px;
            }

            .breadcrumb-item + .breadcrumb-item::before {
                display: inline-block;
                padding-right: 0.4rem;
                padding-left: 0.4rem;
                color: #c49e68;
                content: ">";
            }
        }
    }

    .products {

        @media (max-width: 767.98px) {
            max-width: none;
        }

        @media (max-width: 991.98px) {
            max-width: none;
        }


        #accordion {

            .card {
                border: 0px;

                .card-header {
                    border-radius: 0px;
                    border-bottom: 2px solid #bf955a;
                    padding: 0px;

                    a {
                        cursor: pointer;
                        display: block;
                        padding: 5px 0px;
                        font-size: 1rem;
                        font-family: Gotham Pro Bold;
                        text-transform: uppercase;
                    }
                }

                .card-body {
                    padding: 5px 0px;

                    ul {
                        li {
                            margin-bottom: 1px;
                        }
                    }

                    .pretty {
                        .state {
                            label {
                                color: #414042;
                            }
                        }
                    }


                    .pretty input:checked ~ .state.p-warning label:after, .pretty.p-toggle .state.p-warning label:after {
                        background-color: #bf955a !important;
                    }

                    .pretty .state label:before {
                        border: 2px solid #bb9653;
                    }

                }
            }
        }

        .product-items-container {
            .grid-row {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                margin-bottom: 1rem;
            }

            .grid-item {
                flex-basis: 20%;
                -ms-flex: auto;
                width: 259px;
                position: relative;
                padding: 10px;
                box-sizing: border-box;

                a {
                    display: block;

                    .overlay-container {
                        position: relative;
                        height: 100%;
                    }
                }

                .overlay {
                    width: 100%;
                    height: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba(#bf955a, .6);
                    color: #ffffff;
                    opacity: 0;
                    transition: all ease-in .5s;
                    z-index: 0;
                    //border: 4px solid #ffffff;

                    p {
                        margin-bottom: 0px;
                        text-transform: uppercase;
                        font-size: .9rem;
                        transition: all ease-in .5s;
                        transition: font-size ease-in .6s;
                    }
                }

                img {
                    z-index: 1;
                }
            }

            .grid-item:hover {

                a {
                    text-decoration: none;
                }

                .overlay {
                    opacity: 1;
                    height: 100%;

                    p {
                        font-size: .9rem;
                    }
                }
            }

            @media(max-width: 1333px) {
                .grid-item {
                    flex-basis: 33.33%;
                }
            }

            @media(max-width: 1073px) {
                .grid-item {
                    flex-basis: 33.33%;
                }
            }

            @media(max-width: 815px) {
                .grid-item {
                    flex-basis: 33.3%;
                }
            }

            @media (max-width: 767.98px) {
                .grid-item {
                    flex-basis: 33.3%;
                }
            }

            @media(max-width: 555px) {
                .grid-item {
                    flex-basis: 100%;
                }
            }
        }
    }

    //end
}


.or-devider{
    color: #000;
    text-transform: uppercase;
    position: relative;
    &::before{
        content: "";
        position: absolute;
        top: 50%;
        right: 30px;
        width: 89px;
        height: 2px;
        background-color: #be945b;
        transform: translateY(-50%);
    }
    &::after{
        content: '';
        position: absolute;
        top: 50%;
        left: 30px;
        width: 89px;
        height: 2px;
        background-color: #be945b;
        transform: translateY(-50%);

    }
}

.contineu-gust{
    a{
        width: 100%;
        max-width: 218px;
    }
}

// search and checkout style add this section 

.personal-d{
    margin-bottom: 20px;
}
.billing-d{
    margin-bottom: 16px;
}

.shiping-d {
    margin-bottom: 42px;
}
