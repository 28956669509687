.footer{
  background-color: #f6f2ee;
  padding: .6rem 15px;
  border-top: 2px solid #bf955a;
    font-size: 12px;



    .nav{
    li:first-child{
      margin-right: 6px;
    }
  }
  form{
    label{
      font-family: "Gotham Pro Light";
        font-size: 12px;
        line-height: 2 !important;

      @media (min-width: 768px) {
        margin-right: 5px;
      }
    }
    input.form-control{
      border-radius: 0px;
      border: 0px;
        margin-right: 10px;
        min-width: 200px;
        max-width: 200px;
    }

      .btn{
          max-height: 32px;
          border: 0px;
          padding: 0px 15px !important;
          min-height: 32px;
      }
  }

    .footer-links{
        a{
            padding: 0px 0px 3px 0px;
            text-decoration: none;
        }
    }
}


.cuisine-text{
  .cusine-title{
    font-family: 'Gotham Pro Bold';
    font-size: 16px;
    color: #212529;
    margin-bottom: 6px;

  }
  .cusine-txt{
    font-family: 'Gotham Pro Light';
    font-size: 14px;
    color: #212529;
    margin-bottom: 0;
  }
}