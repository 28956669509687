.header{
 //background-color: #ededed;
  color: #c49e68;
  padding: 10px 15px;
  border-bottom: 2px solid #bf955a;

  .menu{
    li{
      border-right: 1px solid #c49e68;
      padding: 0px 10px;
      text-transform: uppercase;
      font-size: .7rem;
      font-family: "Gotham Pro Bold";
      letter-spacing: .5px;

      a{
        color: #c49e68;
        text-decoration: none;
      }
    }
    li:first-child{
        @media (min-width: 576px) {
            padding-left: 0px;
        }
    }
    li:last-child{
      border: 0px;
    }
  }

  .menu-icons{
    li{
      padding-left: 10px;

      .badge{
        margin-left: -12px;
        border-radius: 25px;
        //background-color: #000;
        letter-spacing: .5px;
        min-width: 20px;
      }
      .account-dropdown-menu{
        padding: 10px 10px;

        .dropdown-item,.dropdown-item:active,.dropdown-item:focus{
          color: #bf955a;
          background:none;
        }
        .dropdown-divider{
          border-color: rgba(191,149,90,0.20);
        }
      }
      .dropdown-menu{
        border-radius: 0px;
        border: 1px solid #bf955a;
        top: 10px !important;
        padding: 15px 10px;
        min-width: 400px;

        @media (max-width: 575.98px) {
          min-width: 300px;
          max-width: 300px;
          top: 20px !important;
        }

        .table{
          margin-bottom: 0px;
          tr{

            &:first-child{
              td{
                border: 0px;
              }
            }
            td{
              padding: .75rem .5rem;
              border-top: 1px solid rgba(191,149,90,0.20);
              vertical-align: middle;

              &:last-child{
                vertical-align: top;
              }

              img{
                max-width: 75px;
                max-height: 100px;
              }
              p{
                color: #bf955a;
                margin-bottom: 0px;
                line-height: 21px;
              }
              span{
                font-size: 10px;
                color: #616161;
                letter-spacing: 0.33px;
                line-height: 15px;
                font-size: 13px;
              }
              .price-row{
                .price{
                  font-family: "Gotham Pro Bold";
                  font-size: 16px;
                  color: #BF955A;
                }
              }
            }
          }
        }
      }
    }
  }
}

.main-menu{
  color: #c49e68;
  font-size: .85rem !important;
  font-family: "Gotham Pro Bold", sans-serif;
  letter-spacing: .5px;
  text-transform: uppercase;
  margin-bottom: 15px;
  
  li{
    margin-right: 2rem;
    border-top: 3px solid transparent;
    padding: 2px 0;
    display: flex;
    justify-content: center;
    align-items: center;

      @media (max-width: 767.98px) {
          margin-left: 1rem;
          margin-right: 1rem;
      }

    &:hover{
      border-top: 3px solid #bf955a;
    }

    a{
      color: #c49e68;
      text-decoration: none;
    }
    &:last-child{
      &:hover{
        border-top: 3px solid #fff;
      }
    }
    .input-group{
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      input{
        border-radius: 0;
        border-left: none;
        border-top: none;
        border-right: none;
        text-align: left;
        padding-left: 0;
        text-transform: uppercase;
        font-family: 'Gotham Pro Medium';
        color: #c49e68 !important;
        font-size: 12px;
        border-bottom-color:  #c49e68;
        padding-bottom: 2px;
        width: 200px;
        &:focus,
        &:active{
          box-shadow: none;
          outline: none;
        }
        &::placeholder{
          font-family: 'Gotham Pro Medium';
          color: #c49e68 !important;
          font-size: 12px;
        }
      }
    }
    button{
      margin: 0;
      padding: 0;
      width: 20px;
      height: 20px;
      background-color: transparent;
      border: none;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;
      cursor: pointer;
      &:active,
      &:focus,
      &:hover{
        outline: none;
        border: none;
      }
      img{
        width: 100%;
      }
    }
  }

  li:last-child{
    margin-right: 0px;
  }

    .dropdown:hover > .dropdown-menu {
        display: block;
    }
    //.dropdown > .dropdown-toggle:active {
    //    /*Without this, clicking will make it sticky*/
    //    pointer-events: none;
    //}

    .dropdown-menu{
        margin-top: -2px;
        font-size: .85rem !important;
        //background-color: transparent;
        //background-color: #979798a8;
        background-color: #3f2b10cf;
        border: 0px;

        .dropdown-item{
            transition: all ease-in-out .5s;

            &:hover{
                background-color: transparent;
                padding-left: 15px;
                color: #c49e68;
            }
        }
    }
}
