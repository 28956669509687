html.home {
  body{
    //background-color: #ededed;
  }

  .banner {
    position: relative;
    //background-image: url("../img/home/banner.png");
    padding: 11rem 15px;
      min-height: 550px;
    //height: calc(100vh - 0px);

    @media (max-width: 575.98px) {
      min-height: 400px;
      max-height: 400px;
      padding-top: 13rem;

      h1{
        font-size: 2.5rem;
      }
    }

    .main-menu{
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
        li{
            a{
                color: #f0c282;
            }
        }
    }

      h1{
          z-index: 1;
          //color: #f0c282;
          color: #e4bb82;
      }

      .home-banner-slider{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;

          div{
              height: 100%;
              min-height: 100%;

              .slick-slide{
                  background-size: cover;
                  background-position: center;
                  background-repeat: no-repeat;
                  z-index: 0;

                  .slider-caption{
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding: 15px;
                      flex-direction: column;

                      h2{
                          z-index: 1;
                          color: #e4bb82;
                          font-size: 30px !important;
                          line-height: 1.5;
                      }
                  }
              }
          }
      }
  }

  .product-container {
    img {
      min-width: 100%;
    }

    @media (max-width: 575.98px) {
      .container {
        padding: 0px;
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #404040;
      opacity: 0;
      transition: all .5s ease-in;
    }

    .hover-content {
      text-align: center;
      width: 100%;
      margin-top: 0px;
      transition: all .5s ease-out;
      z-index: 1;

      a,a:hover{
        text-decoration: none;
      }

      h3 {
        color: #bf955a;
        font-family: "Gotham Pro Bold";
        text-transform: uppercase;
      }
      .dash {
        height: 4px;
        width: 60px;
        background-color: #bf955a;
        display: inline-block;
        transition: all .5s ease-out;
      }
    }

    .product:hover {
      .overlay {
        opacity: .5;
      }
      .hover-content {
        margin-top: 10px;
      }
    }

  }

  .bottle-container {
    background-color: #f6f2ee;
    padding: 4rem 15px;

    @include media-breakpoint-up(xs) {
      padding: 2rem 15px;
    }

    .content-box {
      background-image: url("../img/home/leaf.png");
      background-position: left;
      background-repeat: no-repeat;

      .text-box {
        padding-left: 4rem;
        border-left: 2px solid #bf955a;

        @media (max-width: 575.98px) {
          position: relative !important;
          padding-left: 0px;
          border: 0px;
          margin-top: 0;
        }

        a{
          text-decoration: none !important;
          //color: inherit;
        }

        h2 {
          font-family: "Gotham Pro Bold";
          border-bottom: 2px solid #bf955a;
          text-transform: uppercase;
          padding-bottom: 13px;
          text-decoration: none;
            color: #bf955a;
        }
        p {
          margin-top: 13px;
          font-size: 13px;
        }
      }
    }
  }

  .spa-container{
    .left{
      background-image: url("../img/home/leaf-l.png");
      background-position: top right;
      background-repeat: no-repeat;
      background-size: contain;

      div{
        a{
          text-decoration: none !important;
          //color: inherit;
        }
        h2{
          text-transform: uppercase;
          display: inline-block;
          border-bottom: 2px solid #bf955a;
          padding-bottom: 7px;
          margin-bottom: 15px;
            color: #bf955a;
        }
        p{
          font-size: 13px;
        }

        @media (min-width: 1200px) {
          width: 85%;
        }
      }
    }

    .right{
      background-image: url("../img/home/leaf-r.png");
    }

      strong{
          color: #bf955a;
          font-family: "Gotham Pro Bold";
          font-size: 15px;
          display: block;
          margin-bottom: 5px;
      }
  }

  .home-video-container{
    //padding: 3.5rem 15px;

    video{
      width: 100%;
      height: auto;
    }
  }

}
