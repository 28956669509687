* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.main-banner{

    min-height: 440px;

    @media (max-width: 575.98px) {
        min-height: 400px;
        max-height: 400px;
    }
}

.no-padding-top-bottom{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.no-padding{
  padding: 0px;
}

.center-v {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.center-v-h{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.full-width-bg{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.title-color{
  color: #bf955a;
}
.light-sm-title{
  color: #686868 !important;
}
.dark-sm-title{
  color: #4A4A4A !important;
}
.text-light-grey{
  color: #414042;
}
.black-text{
  color: #000000;
}
.dark-bg{
  background-color: #171716;
}
.light-bg{
  background-color: #f6f2ee;
}

.title-2{
  color: #bf955a;
  text-transform: uppercase;
  padding-bottom: 7px;
  margin-bottom: 20px;
}
.title-border{
  border-bottom: 2px solid #bf955a;
}
.title-border-y{
  border-bottom: 2px solid #bf955a;
  border-top: 2px solid #bf955a;
}
.line-height-normal{
  line-height: normal;
}
.text-decoration{
    text-decoration: underline !important;
}
.text-decoration-none{
    text-decoration: none !important;
}

.b-bottom-primary{
    border-bottom: 1px solid #bf955a;
    line-height: 1.5;
}
.b-bottom-primary-2x{
    border-bottom: 2px solid #bf955a;
}

body{
  font-family: Gotham Pro Light;
  font-weight: normal;
  font-size: 13px;
  letter-spacing: .5px;
}

hr{
    border-top: 2px solid #bf955a;
}
.text-1x{
    font-size: 15px;
}
h1{
  font-family: Gotham Pro Bold !important;
  color: #bf955a;
  text-transform: uppercase;
  font-size: 4rem;
}

h2,h3,h4,h5{
  font-family: Gotham Pro Bold !important;
  text-transform: uppercase;
}

//font size 20 for this
h5{
  font-size: 1.10rem;
}

.h5-title{
  color: #bf955a;
  line-height: 21px;
  font-weight: bold;
  font-family: "Gotham Pro Medium";
  font-size: 13px;
}

h6{
    font-family: Gotham Pro Bold !important;

}
.no-padding-xs{
  @media (max-width: 575.98px) {
    padding: 0px;
  }
}

.max-w-l{
  max-width: 1440px;
}

.webkit-appearance-none {
  -webkit-appearance: none !important;
  -moz-appearance:    none !important;
  appearance:         none !important;
}


//common nav and site map
.menu-container{
  nav{
    .breadcrumb{
      background-color: transparent;
      padding-left: 0px;
      margin-bottom: 0px;
    }

    li,li a{
      color: #c49e68;
      text-decoration: none;
      font-size: 12.5px;
    }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.4rem;
      padding-left: 0.4rem;
      color: #c49e68;
      content: ">";
    }
  }
}

.btn{
  max-height: 35px;
  min-height: 35px;
  //font-size: .9rem;
  padding: 0.35rem 1.5rem;

}

.btn-bold{
  font-weight: bold;
  font-family: "Gotham Pro Bold";
  text-transform: uppercase;
}

.btn-primary{
  background-color: #bf955a;
  border: 0px;
  border-radius: 0px;
  color: #fff;
  //max-height: 35px;
  border: 2px solid #bf955a;
  margin-top: 0px !important;
  letter-spacing: 0.5px;
    padding: 5px 24px;
    font-size: 15px;
}

.btn-outline-primary, .btn-outline-primary:hover{
  background-color: #ffffff;
  border: 2px solid #bf955a;
  border-radius: 0px;
  margin-top: 0px !important;
  font-size: .9rem;
  color: #414042;
  letter-spacing: 0.5px;
}
.btn-primary:hover {
  color: #fff;
  background-color: #bf955a;
  border-color: #bf955a;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0rem rgba(255, 255, 255, 0);
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #bf955a;
  border-color: #bf955a;
}

.dropdown-menu-auto{
  min-width: auto !important;
  max-width: none !important;
}
.form-control{
  font-size: .85rem;
}

.table-bordered thead th, .table-bordered thead td {
  border-bottom-width: 1px;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #BF955A;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.form-check .custom-control-label {
  color: #2c2b2c;
  font-size: 12px;
  line-height: 2.7;
  padding-left:5px;
  text-transform: uppercase;
}
.form-check .custom-control-label::after,
.form-check .custom-control-label::before {
  height: 15px;
  width: 15px;
}
.form-check .custom-control-label::before {
  background-color: #fff;
  border: 1px solid #C0C0C0;
  //border-radius: 50%;
}

/* When I remove background the icon goes back to that default size */
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #BF955A !important;
}

.share-buttons{
  /* container */
    width: 100%;
    margin: 100px auto;
    text-align: center;
  /* buttons */

   a {
     width: 30px;
     height: 30px;
     display: inline-block;
     margin: 4px;
     border-radius: 50%;
     font-size: 13px;
     color: #fff;
     opacity: 0.75;
     transition: opacity 0.15s linear;
     background-color: #be945a;
  }

   a:hover {
    opacity: 1;
  }

  /* icons */

   i {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  /* colors */

  //.facebook {
  //  background: #3b5998;
  //}
  //
  //.twitter {
  //  background: #55acee;
  //}
  //
  //.googleplus {
  //  background: #dd4b39;
  //}
  //
  //.linkedin {
  //  background: #0077b5;
  //}
  //
  //.pinterest {
  //  background: #cb2027;
  //}
}

.tab-menu{
  nav{
    div{
      background-color: #bf955a;
      padding: .5rem 0;
      a{
        color: #ffffff;
        padding: 0rem 2rem;
        font-family: "Gotham Pro Medium";
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: .5px;

        &:last-child{
          border-left: 1px solid #ffffff;
        }
      }
    }
  }
}

.blog-items{
  a{
    text-decoration: none;
  }
}
