html.cuisine {
    .cuisine-banner {
        background-image: url("../img/cusine-banner.png");
        min-height: 440px;

        .banner-content {
            min-height: 300px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;

            h3 {
                padding-bottom: 5px;
                border-bottom: 2px solid;
                width: 75%;
                @media (max-width: 991.98px) {
                    color: #fcd295 !important;
                }
            }

            p {
                font-size: .8rem;
                color: #ffffff;
                font-family: Gotham Pro Light;
            }
        }
    }

    .cusine-menu-container {

        background-color: rgb(246, 242, 238);

        //@media (max-width: 575.98px) {
        //    padding: 0;
        //}

        .container {
            text-align: center;

            .cusine-menu {
                background-color: rgb(191, 149, 90);
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                @media (max-width: 767.98px) {
                    flex-direction: column;
                    padding: 15px 0;
                    margin: 0 -15px;
                }

                @media (min-width:576px){
                    min-height: 50px;
                }

                @media (min-width:768px){
                    //min-height: 75px;
                    padding-top: 17.5px;
                    padding-bottom: 17.5px;
                    align-items: flex-start;
                }

                a {
                    flex: 1;
                    text-align: center;
                    font-size: 15px;
                    font-family: "Gotham Pro Bold";
                    color: rgb(255, 255, 255);
                    font-weight: bold;
                    text-transform: uppercase;
                    line-height: 1;
                    border-right: 2px solid #ffffff;
                    text-decoration: none;
                    padding: 0 5px;

                    @media (max-width: 767.98px) {
                        border-right: 0;
                        margin: 7px 0;
                    }

                    @media (min-width: 768px) and (max-width: 991.98px) {
                        font-size: 12px;
                    }

                    &:last-child {
                        border-right: 0px;
                    }
                }
            }

            .text-container {
                padding: 35px 15px 70px 15px;
                background-image: url("../img/home/leaf.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;

                h3 {
                    text-transform: uppercase;
                    line-height: 1;
                    text-align: center;
                    display: inline-block;
                    padding: 0px 4rem 15px 4rem;
                    border-bottom: 2px solid #bf955a;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 13px;
                }
            }
        }
    }

    .menu-download {
        display: flex;
        padding-top: 10px;
        //border-bottom: 2px solid #bf955a;
        padding-bottom: 10px;
        flex-wrap: wrap;


        @media (min-width: 992px) {
            flex-wrap: nowrap;
        }

        .menu-item {
            //flex: 1;
            padding: 10px;
            text-align: center;
            overflow: hidden;

            @media (min-width: 992px) {
                width: 33%;
            }
            @media (max-width: 991.98px){
                width: 50%;
                //flex: auto;
            }
            @media (max-width: 575.98px){
                width: 100%;
                //flex: auto;
            }
            img{
                min-width: 100%;
                max-width: 100%;
            }

            h5 {
                color: rgb(191, 149, 90);
                text-transform: uppercase;
                line-height: 15px;
                padding: 0px;
                margin: 13px 0px 10px 0px;
                font-size: 15px;

                text-overflow: ellipsis;
                word-wrap: break-word;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;

                @media (min-width: 576px) {
                    min-height: 30px;
                    max-height: 30px;
                }

                @media (min-width: 992px) and (max-width: 1199.98px) {
                    font-size: 12px;
                }
            }

            a {
                background-color: rgb(191, 149, 90);
                height: 40px;
                color: #ffffff;
                display: block;
                text-decoration: none;
                font-size: 1.10rem;
                font-family: Gotham Pro Bold !important;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;
                line-height: 1;
                padding: 0px 10px;

                i {
                    margin-left: 10px;
                }
            }
        }
    }

    .menu-section {
        .slick-ctrl {
            color: #bf955a;
            font-size: 18px;
            cursor: pointer;
        }

        .menu-container {
            flex: 1;
            max-width: 100%;
            overflow: hidden;
            padding: 0px 15px;
            //display: flex;
            //flex-wrap: wrap;
            //align-content: flex-start;

            .menu-item {
                text-align: center;

                //@media (max-width: 575.98px) {
                //
                //}
                //@media (min-width: 576px) and (max-width: 767.98px) {
                //  width: 50%;
                //}
                //@media (min-width: 768px) {
                //  width: 33%;
                //  &:nth-child(1) a{
                //    border-bottom: 2px solid;
                //  }
                //  &:nth-child(2) a{
                //    border-bottom: 2px solid;
                //  }
                //  &:nth-child(3) a{
                //    border-bottom: 2px solid;
                //  }
                //}


                &:focus {
                    outline: none;
                    border: 0px;
                }

                a {
                    color: #bf955a;
                    text-decoration: none;
                    font-family: Gotham Pro Bold;
                    font-size: .85rem;
                    width: auto;
                    padding-bottom: 7px;
                    //min-width: 168px;
                    display: inline-block;
                    padding-left: 0px;
                    padding: 15px 0px;

                    //@media (max-width: 767.98px) {
                    //  border-bottom: 2px solid;
                    //}

                    //&:first-child{
                    //  margin-bottom: 0px;
                    //  border-bottom: 2px solid;
                    //}
                    &:hover {
                        border-bottom: 2px solid;
                    }

                    &:focus {
                        outline: none;
                        border: 0px;
                    }

                    &.active {
                        border-bottom: 2px solid;
                    }
                }

            }

        }

        .menu-items-container {
            #tabContents {
                table {
                    font-family: Gotham Pro Bold;

                    thead {
                        h3 {
                            color: #bf955a;
                            padding-bottom: 5px;
                            display: inline-block;
                            border-bottom: 2px solid;
                            text-transform: uppercase;
                        }
                    }

                    tr {
                        th.light {
                            font-family: Gotham Pro;
                            font-size: 13px;
                        }

                        td {
                            font-size: 13px;
                            border: 0px;
                        }

                        th {
                            padding-top: 10px;
                            padding-bottom: 10px;
                            font-size: 18px;
                            border: 0px;
                        }
                    }
                }
            }
        }
    }

    .blog-items {
        > div {
            padding: 0px;
        }

        img {
            object-fit: cover;
            min-width: 100%;
            max-height: 200px;
        }

        a {
            text-decoration: none;
        }

        p {
            font-size: 13px;
            font-family: "Gotham Pro Light";
            color: rgb(65, 64, 66);
            line-height: 1.2;
            text-align: left;
        }
    }
}

html.cuisine-inner{

    .cusine-menu-container{
        background: none !important;

        >.container{
            //border-bottom: 2px solid #bf955a;
            padding-bottom: 10px;
        }

    }
    .weekday-menu {
        font-size: 15px;
        font-family: "Gotham Pro Bold";
        color: rgb(191, 149, 90);
        text-transform: uppercase;
        line-height: 1.2;
        text-align: left;

        @media (max-width: 575.98px) {
            padding-bottom: 30px;
        }

        .nav-item{
            border-bottom: 2px solid #bf955a;
            .nav-link{
                padding: 0.7rem 1rem 0.4rem 0rem;
            }
        }
    }

    .menu-details-container{
        padding-top: 11px;
        color: #414042;

        h3{
            text-transform: uppercase;
            line-height: 1;
            text-align: left;
            display: inline-block;
            padding: 0px 9rem 10px 0rem;
            border-bottom: 2px solid #bf955a;
            margin-bottom: 20px;
            min-width: 280px;
        }

        h5{
            //margin-bottom: 0px;

        }
        .item{
            margin-bottom: 15px;

            strong{
                font-family: "Gotham Pro Bold";
                font-size: 13px;
            }
            span{
                display:block;
                font-family: "Gotham Pro Light";
                font-size: 13px;

                p{
                    //display: inline-block;
                    margin-bottom: 0;
                }
            }

            .form-check{
                .custom-control{
                    display: flex;
                    align-items: center;
                    padding-left: 17px;

                    input{
                        &:focus{
                            outline: none !important;
                            box-shadow: none !important;
                        }
                    }
                    label{
                        color: #bf955a;
                        font-size: 13px;
                        line-height: 1;
                        text-transform: capitalize;
                    }
                }

                .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
                    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23BF955A' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
                    }

                .custom-control-label::before {
                    background-color: #fff;
                    border: 2px solid #bf9559;
                }

            }

            label{
                color: #bf955a;
                font-size: 13px;
                line-height: 1;
                text-transform: capitalize;
            }

        }

        .item-seperater{
            border-bottom: 2px solid #bf955a;
            min-width: 280px;
            max-width: 350px;
            padding-top: 10px;
            margin-bottom: 15px;
        }
    }

}
